export const KEY_ESCAPE = 'Escape'

export const LOCATION_MARKER_ZINDEX_OFFSET = 1100
export const MARKER_ZINDEX_OFFSET = 1000

export const TYPE_LOCATION = 'location'

// Arbitrary fence to enclose location context
export const TYPE_GEOFENCE = 'geofence'

// buildings the enclosing perimeter of an
// isolated building, which encloses one or more
// floors
export const TYPE_BUILDING = 'building'

// floors group sub areas that are all contained
// on the same level
export const TYPE_FLOOR = 'floor'

// rooms are areas which have enclosing walls
// and transitions in and out (doors)
export const TYPE_ROOM = 'room'

// areas are rooms, but without walls. E.g. an
// open space
export const TYPE_AREA = 'area'

// corridors indicate walkways within a floor
export const TYPE_CORRIDOR = 'corridor'

// walls are non-enclosing obstructive
// boundaries within another area
export const TYPE_WALL = 'wall'

// desk, table etc.
export const TYPE_OBJECT = 'object'

// transitions indicate a method of moving into
// another area. E.g. door, escalator, stairs
export const TYPE_TRANSITION = 'transition'

// ways indicating a path on which to navigate
// through an area. Useful for indoor navigation
export const TYPE_WAY = 'way'

// landscapes are contextual objects to indicate
// surroundings, such as grass
export const TYPE_LANDSCAPE = 'landscape'

// roads are not inherently useful to us, but
// indicate context to end users
export const TYPE_ROAD = 'road'

export const TYPE_POINT = 'point'

export const AREA_FIELDS = [
  'address',
  'areaSize',
  'audits',
  'bbox',
  'center',
  'childOf',
  'entries',
  'floors',
  'floorsRef',
  'geometry',
  'issues',
  'labelAnchor',
  'legacyId',
  'lineDistance',
  'name',
  'plugins',
  'reference',
  'search',
  'serviceHours',
  'tasks',
  'timezone',
  'type',
  'ui',
]
