import { colors } from 'config/theme'

export const AREA_TYPES = ['location', 'building', 'floor', 'room', 'point']

export const LANGUAGES = [
  {
    label: 'English (US)',
    labelT: 'labelEnglishUs',
    shortLabel: 'English (US)',
    shortLabelT: 'labelEnglishUsShort',
    value: 'en-US',
  },
  {
    label: 'French (Canadian)',
    labelT: 'labelFrenchCa',
    shortLabel: 'French (CA)',
    shortLabelT: 'labelFrenchCaShort',
    value: 'fr-CA',
  },
  {
    label: 'Spanish (Latin-America)',
    labelT: 'labelSpanishLa',
    shortLabel: 'Spanish (LA)',
    shortLabelT: 'labelSpanishLaShort',
    value: 'es-419',
  },
]

export const DEFAULT_LANGUAGE = 'en-US'

export const ACTIVITY_ISSUE_CATEGORY_NEW = 'issue-new'
export const ACTIVITY_ISSUE_CATEGORY_UPDATE = 'issue-update'

export const ATHENA_PER_PAGE = 500

export const DEFAULT_PER_PAGE = 50
export const DEFAULT_PRIMARY_SORT_DIRECTION = 'ASC'
export const DEFAULT_REPORTING_PERIOD_LONG = 7
export const DEFAULT_REPORTING_PERIOD_SHORT = 1
export const DEFAULT_THRESHOLD = 25

export const SERVICE_LEVEL_ABOVE = 'above'
export const SERVICE_LEVEL_ON = 'on'
export const SERVICE_LEVEL_BELOW = 'below'

export const STATUS_CLOSED = 'closed'
export const STATUS_CLOSED_COLOR = colors.red.normal
export const STATUS_CLOSED_TEXT = 'Closed'
export const STATUS_IN_PROGRESS = 'in-progress'
export const STATUS_IN_PROGRESS_COLOR = colors.blue.normal
export const STATUS_IN_PROGRESS_TEXT = 'In Progress'
export const STATUS_OPEN = 'open'
export const STATUS_OPEN_COLOR = colors.orange.normal
export const STATUS_OPEN_TEXT = 'Open'
export const STATUS_UNKNOWN_COLOR = colors.gray.normal
export const STATUS_UNKNOWN_TEXT = 'Unknown Status'

export const TOOLTIPS = {
  adhoc: {
    message:
      'Record location when users take specific actions using the mobile app. Actions include start/end shift, qr code scan, submit task, submit issue, and submit audit.',
    messageT: 'roles.adhocTooltip.message',
    title: 'Record location on actions',
    titleT: 'roles.adhocTooltip.title',
  },
  advertisingInterval: {
    message: `The delay between broadcast advertising packets. This should
    be a positive integer.`,
    messageT: 'tooltip.message.advertisingInterval',
    title: 'Advertising Interval',
    titleT: 'tooltip.title.advertisingInterval',
  },
  apiError: {
    message: null,
    messageT: null,
    title: 'API Error',
    titleT: 'tooltip.title.apiError',
  },
  applicationId: {
    message:
      'Your application id is your unique application identifier. It is a single lower case word without spaces (e.g. "company-name"). If you cannot remember your application id, contact support@lighthouse.io.',
    messageT: 'login.applicationIdTooltip.message',
    title: 'Application ID',
    titleT: 'login.applicationIdTooltip.title',
  },
  auditTarget: {
    message: `Add the number that you want as the performance standard in the Target field. Entering an "85" in the Target field would set the measure standard to 85%.`,
    messageT: 'audit.targetTooltip.message',
    title: 'Enter Target',
    titleT: 'audit.targetTooltip.title',
  },
  authStrategy: {
    message: `If this feature is available for your organisation, please choose the preferred user authentication provider.`,
    messageT: 'users.authStrategyTooltip.message',
    title: 'Authentication Provider',
    titleT: 'users.authStrategyTooltip.title',
  },
  beaconMajor: {
    message: `Used to group a related set of beacons (e.g. all
      beacons at a single location).`,
    messageT: 'tooltip.message.beaconMajor',
    title: 'Beacon Major',
    titleT: 'tooltip.title.beaconMajor',
  },
  beaconMajorAlt: {
    message: `A positive integer used to differentiate a smaller subset of
    beacons within the larger group.`,
    messageT: 'tooltip.message.beaconMajorAlt',
    title: 'Beacon Major',
    titleT: 'tooltip.title.beaconMajor',
  },
  beaconMinor: {
    message: `Used to identify individual beacons (e.g. a single zone
      at a single location).`,
    messageT: 'tooltip.message.beaconMinor',
    title: 'Beacon Minor',
    titleT: 'tooltip.title.beaconMinor',
  },
  beaconMinorAlt: {
    message: `A positive integer that is used to identify
    individual beacons.`,
    messageT: 'tooltip.message.beaconMinorAlt',
    title: 'Beacon Minor',
    titleT: 'tooltip.title.beaconMinor',
  },
  beaconUuid: {
    message: `Unique beacon identifier that helps the mobile application
    distinguish your beacons from other beacons. You should use
    the same UUID for all beacons associated with a particular
    application.`,
    messageT: 'tooltip.message.beaconUuid',
    title: 'Beacon UUID',
    titleT: 'tooltip.title.beaconUuid',
  },
  dayforceEmployeeBadgeNumber: {
    message:
      'For Dayforce clients, you may reference the related Employee Badge Number',
    messageT: 'users.dayforce.message',
    title: 'Employee Badge Number',
    titleT: 'users.dayforce.employeeBadgeNumber',
  },
  dayforceLocationCode: {
    message:
      'For Dayforce clients, you may reference the related Location XRef Code',
    messageT: 'tooltip.message.locationXRefCode',
    title: 'Dayforce Location XRef Code',
    titleT: 'tooltip.title.locationXRefCode',
  },
  document: {
    message: 'Supported File Types: PDF, DOC, DOCX',
    messageT: 'tooltip.message.document',
    title: 'File Upload',
    titleT: 'tooltip.title.document',
  },
  confirmLocation: {
    message:
      'This setting will require mobile users to confirm their location when starting a shift. For automatic shift starts, they must confirm a location to log in. For manual shift starts, they must select a location to start the shift.',
    title: 'Location on Shift Start',
    messageT: 'tooltip.message.confirmLocation',
    titleT: 'tooltip.title.confirmLocation',
  },
  employeeNumber: {
    message:
      'For WinTeam clients, you may reference the related Employee Number.',
    messageT: 'users.winteamEmployeeNumberTooltip.message',
    title: 'WinTeam Employee Number',
    titleT: 'users.winteamEmployeeNumberTooltip.title',
  },
  endShift: {
    message: `If you set End Shift to 'Automatically' the user will end a new shift as soon as
    they logout out of the Lighthouse mobile application. This removes the need to manually
    end shifts on the device.`,
    messageT: 'users.endShiftTooltip.message',
    title: 'End Shift',
    titleT: 'users.endShiftTooltip.title',
  },
  field: {
    message: 'A field represents a single input in your form.',
    messageT: 'template.fieldTooltip.message',
    title: 'Field',
    titleT: 'template.fieldTooltip.title',
  },
  groupName: {
    message: `A group is an organizing mechanism for grouping sets of form fields.`,
    messageT: 'template.groupNameTooltip.message',
    title: 'Group Name',
    titleT: 'template.groupNameTooltip.title',
  },
  jobNumber: {
    message: 'For WinTeam clients, you may reference the related Job Number',
    messageT: 'areas.jobNumberTooltip.message',
    title: 'WinTeam Job Number',
    titleT: 'areas.jobNumberTooltip.title',
  },
  locationServiceHours: {
    message: `Setting these up is helpful for monitoring purposes, as it determines when activity is expected at this location (e.g. for schedules).`,
    messageT: 'location.serviceHoursTooltip.message',
    title: 'Service Hours',
    titleT: 'location.serviceHoursTooltip.title',
  },
  locations: {
    message: `Add locations to provide access only to specific sites. Not specifying a location will
    provide default access to all.`,
    messageT: 'users.locationsTooltip.message',
    title: 'Select Locations',
    titleT: 'users.locationsTooltip.title',
  },
  mapZoomLevel: {
    message: `Represents the default zoom on the map or floor plan.
    A standard map zoom would be 17 or higher.`,
    messageT: 'tooltip.message.mapZoomLevel',
    title: 'Map Zoom Level',
    titleT: 'tooltip.title.mapZoomLevel',
  },
  media: {
    message: 'Supported File Types: PNG, JPG, JPEG, MP4',
    messageT: 'tooltip.message.media',
    title: 'Media Upload',
    titleT: 'tooltip.title.media',
  },
  nfcTagId: {
    message: `All NFC tags are manufactured with a unique tag
    identifier. These are typically 14 hexadecimal characters
    (e.g. 04 9C 64 D2 45 2B 80) and cannot be modified. These tag
    IDs are assigned to the signal to identify the tag when
    scanned by the Lighthouse Mobile App.`,
    messageT: 'tooltip.message.nfcTagId',
    title: 'NFC Tag ID',
    titleT: 'tooltip.title.nfcTagId',
  },
  passive: {
    message:
      'Record location continuously in the background, even if the mobile app is closed',
    messageT: 'roles.passiveTooltip.message',
    title: 'Always record location',
    titleT: 'roles.passiveTooltip.title',
  },
  photo: {
    message: 'Supported File Types: PNG, JPG, JPEG',
    messageT: 'tooltip.message.photo',
    title: 'Photo Upload',
    titleT: 'tooltip.title.photo',
  },
  reference: {
    message: `Reference fields are free text fields, typically used to support integrations with external solutions. They do not appear within the mobile app, reports or exports.`,
    messageT: 'tooltip.reference.message',
    title: 'Reference Field',
    titleT: 'tooltip.reference.title',
  },
  required: {
    message: `Yes - Question requires response. Form will not submit with unanswered questions

    No - Users can skip question. Skipped questions will not impact audit score`,
    messageT: 'template.requiredTooltip.message',
    title: 'Required',
    titleT: 'template.requiredTooltip.title',
  },
  role: {
    message: `Select the role type of the user and this will apply associated read and write permissions.
    You can setup and manage roles under the 'Roles & Permissions' tab.`,
    messageT: 'users.roleTooltip.message',
    title: 'Role',
    titleT: 'users.roleTooltip.title',
  },
  scheduleArea: {
    message: `Assign to the appropriate Area(s) and Signal(s).`,
    messageT: 'schedules.areasTooltip.message',
    title: 'Select Area(s) and Signal(s)',
    titleT: 'schedules.areasTooltip.title',
  },
  scheduleEnable: {
    message: `Enabled drop down to Yes. If this field is set to No, then this scheduled work item will not be active.`,
    messageT: 'schedules.enableTooltip.message',
    title: 'Schedule Enable option',
    titleT: 'schedules.enableTooltip.title',
  },
  scheduleLocation: {
    message: `Assign to the appropriate location(s). If you select multiple locations you cannot also assign to Signals and Areas.`,
    messageT: 'schedules.locationsTooltip.message',
    title: 'Select Locations',
    titleT: 'schedules.locationsTooltip.title',
  },
  scheduleRepeatOption: {
    message: `The <i>No Repeat</i> option is a one-time occurrence and defaults to use the location service hours.<br/>The <i>Repeat on time since last activity completion</i> option bases the start timer on the time of the activity was completed.<br/>The <i>Repeat on set time intervals</i> option bases the start time on regular intervals starting from the location’s open service hour time.`,
    messageT: 'schedules.repeatTooltip.message',
    title: 'Select Repeat option',
    titleT: 'schedules.repeatTooltip.title',
  },
  scheduleRole: {
    message: `Assign to the appropriate Roles. If the schedule is assigned to multiple roles, it only needs to be completed by one of those users.`,
    messageT: 'schedules.rolesTooltip.message',
    title: 'Select Role(s)',
    titleT: 'schedules.rolesTooltip.title',
  },
  scheduleStart: {
    message: `Set the Start Schedule date, based on when you want this item to start. Note selecting Today will start the schedule exactly at the time submitted (e.g. 1PM if save at 1PM). If you want schedules to commence at the start of the day, select a future date (e.g. tomorrow).

    If there are specific hours for this work to be completed, clear the Use location service hours check box, click the Add Service Hours button and set up the Day/Date, Options, Open Time and Close Time options.
   `,
    messageT: 'schedule.startTooltip.message',
    title: 'Start Schedule option',
    titleT: 'schedule.startTooltip.title',
  },
  scheduleType: {
    message: `Select the Type drop down and select either Visit, Audit, or Task. Visit requires a location event to complete, while Audit/Task requires submission of selected Audit/Task.`,
    messageT: 'schedules.typeTooltip.message',
    title: 'Schedule Type',
    titleT: 'schedules.typeTooltip.title',
  },
  scheduleUser: {
    message: `Assign to the appropriate Users. If the schedule is assigned to multiple users, it only needs to be completed by one of those users.`,
    messageT: 'schedules.usersTooltip.message',
    title: 'Select User(s)',
    titleT: 'schedules.usersTooltip.title',
  },
  score: {
    message: `The score value represents the result of the question in its own context.
    Think of it as a percentage score on a scale of 0-1, e.g. Pass = 1, OK = 0.5
    and Fail = 0.`,
    messageT: 'template.scoreTooltip.message',
    title: 'Score',
    titleT: 'template.scoreTooltip.title',
  },
  shiftGeofenceRestriction: {
    message:
      'This setting will require mobile users to be within the geofence of a location when starting/ending a shift.',
    messageT: 'tooltip.message.shiftGeofenceRestriction',
    title: 'Geofence Restricted Shifts',
    titleT: 'tooltip.title.shiftGeofenceRestriction',
  },
  endShiftRestriction: {
    message:
      "Either allow any location, restrict to the Start Shift Location or always use the Start Shift Location to end shifts. All options will record the user's raw GPS and whether they were inside the Start Shift Location geofence.",
    messageT: 'tooltip.message.endShiftRestriction',
    title: 'End Shift Restrictions',
    titleT: 'tooltip.title.endShiftRestriction',
  },
  signalStrengthIndicator: {
    message: `An numeric value indicating the strength of the beacon's
    signal as seen on the receiving device.`,
    messageT: 'tooltip.message.signalStrengthIndicator',
    title: 'Received Signal Strength Indicator',
    titleT: 'tooltip.title.signalStrengthIndicator',
  },
  startShift: {
    message: `If you set Start Shift to 'Automatically' the user will start a new shift as soon as
    they login to the Lighthouse mobile application. This removes the need to manually
    start shifts on the device.`,
    messageT: 'users.startShiftTooltip.message',
    title: 'Start Shift',
    titleT: 'users.startShiftTooltip.title',
  },
  summaryField: {
    message:
      'Summary fields allow you to display additional information in activity summaries. You can select up to {{MAX_SUMMARY_FIELDS}} summary fields per template.',
    messageT: 'template.summaryFieldTooltip.message',
    title: 'Summary Field',
    titleT: 'template.summaryFieldTooltip.title',
  },
  templateFieldReference: {
    messageT: 'template.fieldReference.message',
    titleT: 'template.fieldReference.title',
  },
  templateRepeatable: {
    message: `When the repeatable group option is enabled on a form template it allows the employee filling out the form to repeat the form group more than once. `,
    messageT: 'template.repeatTooltip.message',
    title: 'Select Repeatable',
    titleT: 'template.repeatTooltip.title',
  },
  timegateEmployeePIN: {
    message:
      'For Timegate clients, you may reference the related Employee PIN.',
    messageT: 'users.timegateEmployeePINTooltip.message',
    title: 'Timegate Employee PIN',
    titleT: 'users.timegateEmployeePINTooltip.title',
  },
  timezone: {
    message: 'The Timezone is the local timezone for the selected location.',
    messageT: 'tooltip.message.timezone',
    title: 'Timezone',
    titleT: 'tooltip.title.timezone',
  },
  transmittingPower: {
    message: `The power with which the signal leaves the beacon's antenna.
    Usually between -30 dBm to +4 dBm`,
    messageT: 'tooltip.message.transmittingPower',
    title: 'Transmitting Power',
    titleT: 'tooltip.title.transmittingPower',
  },
  type: {
    message: `Allows you to specify the input type to be displayed. Text allows simple
    text input, Select allows multiple pre-defined input options, Switch allows
    for a check box input, Media allows media upload.`,
    messageT: 'template.typeTooltip.message',
    title: 'Type',
    titleT: 'template.typeTooltip.title',
  },
  uuid: {
    message: `A UUID Spec v4 string used to differentiate a large
    group of related beacons.`,
    messageT: 'tooltip.message.Uuid',
    title: 'UUID',
    titleT: 'tooltip.title.Uuid',
  },
  validationError: {
    message: null,
    messageT: null,
    title: 'Validation Error',
    titleT: 'tooltip.title.validationError',
  },
  weight: {
    message: `The weight value represents the weighting of this question's score in
    relation to other questions. It should be a positive numerical value.
    If the question matters more than others, give it a higher value. We
    multiply the value with the score for the question when the audit is
    submitted to get an overall total.`,
    messageT: 'template.weightTooltip.message',
    title: 'Weight',
    titleT: 'template.weightTooltip.title',
  },
  winTeamEnabled: {
    message:
      'For WinTeam clients, you may enable or disable WinTeam integration for this user.',
    messageT: 'users.winTeamEnabledTooltip.message',
    title: 'WinTeam Enabled',
    titleT: 'users.winTeamEnabledTooltip.title',
  },
}

export default {
  ACTIVITY_ISSUE_CATEGORY_NEW,
  ACTIVITY_ISSUE_CATEGORY_UPDATE,
  AREA_TYPES,
  ATHENA_PER_PAGE,
  DEFAULT_PER_PAGE,
  DEFAULT_PRIMARY_SORT_DIRECTION,
  DEFAULT_REPORTING_PERIOD_LONG,
  DEFAULT_REPORTING_PERIOD_SHORT,
  DEFAULT_THRESHOLD,
  LANGUAGES,
  SERVICE_LEVEL_ABOVE,
  SERVICE_LEVEL_BELOW,
  SERVICE_LEVEL_ON,
  STATUS_CLOSED,
  STATUS_CLOSED_COLOR,
  STATUS_CLOSED_TEXT,
  STATUS_IN_PROGRESS,
  STATUS_IN_PROGRESS_COLOR,
  STATUS_IN_PROGRESS_TEXT,
  STATUS_OPEN,
  STATUS_OPEN_COLOR,
  STATUS_OPEN_TEXT,
  STATUS_UNKNOWN_COLOR,
  STATUS_UNKNOWN_TEXT,
  TOOLTIPS,
}
