import Radium from 'radium'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import EditFloorForm from './components/edit-floor/index'
import AddFloorForm from './components/add-floor/index'

import { capitalize, get, isEqual } from 'lodash'
import { getModule } from '@lighthouse/sdk'

import Button from 'components/button'
import Icon from 'components/icon'
import Spinner from 'components/spinner'

import AreaDetails from './components/area-details'
import ContentEntry from './components/content-entry'
import ContentWizard from './components/content-wizard'
import TaskTemplateList from './components/task-template-list'
import IssueTemplateList from './components/issue-template-list'
import AuditTemplateList from './components/audit-template-list'
import SyncWizard from './components/sync-wizard'
import styles from './styles'

const initialState = {
  isAddingFloorMode: false,
  isEditing: false,
  isEditFloorMode: false,
  isLoading: false,
  isNewEntryMode: false,
  isAddTaskTemplateMode: false,
  isAddIssueTemplateMode: false,
  isAddAuditTemplateMode: false,
  isSyncEntryMode: false,
  selectedId: null,
  selectedFloor: null,
}

const areaModule = getModule('areas')
const geoModule = getModule('geo')

class AreaView extends Component {
  constructor(props) {
    super(props)

    this.updateAreaView = this.updateAreaView.bind(this)

    this.state = initialState
  }

  componentWillMount() {
    const { isEditing } = this.props

    if (isEditing) {
      this.updateAreaView({
        isEditing: true,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const area = this.props.area
    const nextArea = nextProps.area

    const areaId = get(area, 'entity._id')
    const nextId = get(nextArea, 'entity._id')

    const areaEntries = get(area, 'entity.entries')
    const nextEntries = get(nextArea, 'entity.entries')

    const areaTemplates = get(area, 'entity.templates')
    const nextTemplates = get(nextArea, 'entity.templates')

    const hasIdChanged = !isEqual(areaId, nextId)
    const hasEntriesChanged = !isEqual(areaEntries, nextEntries)
    const hasTemplatesChanged = !isEqual(areaTemplates, nextTemplates)

    // NOTE if no nextId area entry has been deleted so don't set state
    if (
      !nextId ||
      (!hasIdChanged && !hasEntriesChanged && !hasTemplatesChanged)
    )
      return
    if (areaId !== nextId) this.setState(initialState)
  }

  render() {
    const {
      area,
      areaId,
      fetchAreasByArea,
      parentAreaLocationId,
      setBuilding,
      onClose,
    } = this.props

    const {
      isAddingFloorMode,
      isEditFloorMode,
      isEditing,
      isLighthouseAdmin,
      isLoading,
      isNewEntryMode,
      isAddTaskTemplateMode,
      isAddAuditTemplateMode,
      isAddIssueTemplateMode,
      isSyncEntryMode,
      selectedFloor,
      selectedId,
    } = this.state
    if (!area) {
      return null
    }

    const {
      name,
      type,
      entries = [],
      issues = [],
      tasks = [],
      audits = [],
    } = area.entity

    const areaEntries =
      entries && entries.asMutable && entries.asMutable({ deep: true })
    const areaTasks =
      tasks && tasks.asMutable && tasks.asMutable({ deep: true })
    const areaAudits =
      audits && audits.asMutable && audits.asMutable({ deep: true })
    const areaIssues =
      issues && issues.asMutable && issues.asMutable({ deep: true })

    const floors = area.entity.floors

    const hasSelectedAreaEntry = areaEntries.find(
      entryId => entryId === selectedId
    )

    const isBuilding = type === 'building'

    const showAreaDetails =
      !hasSelectedAreaEntry &&
      !isNewEntryMode &&
      !isSyncEntryMode &&
      !isAddingFloorMode &&
      !isAddIssueTemplateMode &&
      !isAddTaskTemplateMode &&
      !isAddAuditTemplateMode &&
      !isEditFloorMode

    return (
      <div>
        <div style={styles.header}>
          <div style={styles.area}>{capitalize(type)}</div>
          <div style={styles.title}>
            {name || `Unknown ${capitalize(type)}`}
          </div>
          <Button type="button" style={styles.closeButton} onClick={onClose}>
            <Icon name="close" theme={styles.iconClose} />
          </Button>
        </div>

        {isLoading && (
          <div style={[styles.spinner]}>
            <Spinner />
          </div>
        )}

        {!isLoading && (
          <div>
            {isNewEntryMode && (
              <ContentWizard
                area={area}
                areaEntries={areaEntries}
                updateAreaView={this.updateAreaView}
              />
            )}
            {isAddTaskTemplateMode && (
              <TaskTemplateList
                area={area}
                areaTasks={areaTasks}
                updateAreaView={this.updateAreaView}
              />
            )}
            {isAddAuditTemplateMode && (
              <AuditTemplateList
                area={area}
                areaAudits={areaAudits}
                updateAreaView={this.updateAreaView}
              />
            )}
            {isAddIssueTemplateMode && (
              <IssueTemplateList
                area={area}
                areaIssues={areaIssues}
                updateAreaView={this.updateAreaView}
              />
            )}
            {isSyncEntryMode && hasSelectedAreaEntry && (
              <SyncWizard
                area={area}
                entryId={selectedId}
                updateAreaView={this.updateAreaView}
              />
            )}
            {!isNewEntryMode && !isSyncEntryMode && hasSelectedAreaEntry && (
              <ContentEntry
                area={area}
                entryId={selectedId}
                updateAreaView={this.updateAreaView}
              />
            )}
            {showAreaDetails && (
              <AreaDetails
                areaId={areaId}
                area={area}
                areaEntries={areaEntries}
                areaTasks={areaTasks}
                areaAudits={areaAudits}
                areaIssues={areaIssues}
                isAddingFloorMode={isAddingFloorMode}
                isBuilding={isBuilding}
                isEditing={isEditing}
                isEditFloorMode={isEditFloorMode}
                updateAreaView={this.updateAreaView}
              />
            )}
            {isEditFloorMode && (
              <EditFloorForm
                areaId={areaId}
                fetchAreasByArea={fetchAreasByArea}
                floors={floors}
                isAddingFloorMode={isAddingFloorMode}
                isEditing={isEditing}
                isEditFloorMode={isEditFloorMode}
                isLighthouseAdmin={isLighthouseAdmin}
                selectedFloor={selectedFloor}
                setBuilding={setBuilding}
                updateAreaView={this.updateAreaView}
                parentAreaLocationId={parentAreaLocationId}
              />
            )}
            {isAddingFloorMode && (
              <AddFloorForm
                areaId={areaId}
                fetchAreasByArea={fetchAreasByArea}
                floors={floors}
                isAddingFloorMode={isAddingFloorMode}
                isEditing={isEditing}
                isEditFloorMode={isEditFloorMode}
                isLighthouseAdmin={isLighthouseAdmin}
                selectedFloor={selectedFloor}
                setBuilding={setBuilding}
                updateAreaView={this.updateAreaView}
                parentAreaLocationId={parentAreaLocationId}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  updateAreaView(state) {
    this.setState(state)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Radium
)(AreaView)

function handleError(entity) {
  return (error = {}) => {
    const message = error.message || `Error fetching ${entity} for area`
    console.error(message)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAreasByArea: areaId =>
      dispatch(
        areaModule.areasByArea(
          areaId,
          {
            perPage: 9999,
          },
          areaId
        )
      ),
    setBuilding: opts => dispatch(geoModule.setBuilding(opts)),
  }
}

function mapStateToProps(state, props) {
  const { areaId } = props

  const area = state.areas.cache[areaId]
  const parentAreaLocationId = get(area, 'entity.childOf[0]')

  return {
    area,
    areaId,
    parentAreaLocationId,
  }
}
