import { round, times, uniqBy } from 'lodash'
import {
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
} from 'react-virtualized'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import { Block, Flex } from 'components/common'
import DropdownItem from '../dropdown-item'
import { useTranslation } from 'react-i18next'

const MAX_HEIGHT = 288
const ROW_HEIGHT = 36

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 36,
})

const listStyles = {
  backgroundColor: 'white',
  position: 'absolute',
  borderLeft: `1px solid ${colors.gray.lighter}`,
  borderRight: `1px solid ${colors.gray.lighter}`,
  borderBottom: `1px solid ${colors.gray.lighter}`,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  zIndex: 10,
}

export default compose(
  withState('scrollIndex', 'setScrollIndex', 0),
  lifecycle({ componentDidMount }),
  withHandlers({
    renderItem,
  })
)(SelectDropdownMenu)

function SelectDropdownMenu(props) {
  const {
    addItems,
    dataTestId,
    highlightedIndex,
    infiniteScrolling,
    inputValue,
    isRowLoaded,
    isSearching,
    items = [],
    listRowCount,
    loadMoreRows,
    refreshing,
    removeItems,
    renderItem,
    scrollIndex,
    selectedItems,
    setScrollIndex,
    totalRowCount,
    width,
    showRemoveItems,
    showSelectAll,
    selectAllLimit,
    type,
  } = props

  const { t } = useTranslation()

  const itemCount = items.length
  const scrollToIndex = highlightedIndex || scrollIndex
  const totalCount = totalRowCount || itemCount
  const totalHeight = itemCount * ROW_HEIGHT
  const height =
    totalHeight > MAX_HEIGHT
      ? MAX_HEIGHT
      : // NOTE What we're doing here is calculating the height of the first X number
        // of rows using the CellMeasurer cache, but only if there's less rows than
        // our max height
        times(itemCount).reduce((acc, count) => {
          return (acc += cache.rowHeight({ index: count }))
        }, 0)

  const searchCount = refreshing ? listRowCount : itemCount

  const rowCount = listRowCount ? searchCount : totalCount

  //These are the items that are currently visible in the dropdown that are selected
  const filteredResult = items.filter(item => selectedItems.includes(item))

  const canSelectAll =
    filteredResult.length !== items.length &&
    uniqBy([...selectedItems, ...items], 'value').length < selectAllLimit

  return (
    <Block dataTestId={dataTestId}>
      {/**
          NOTE highlightedIndex, inputValue, items and selectedItems are needed
          to force re-renders of underlying List. Without these, we've
          observed a strange issue where _only_ the first item (index === 0)
          will have trouble re-rerendering and other items in the list also don't
          always update as expected
      **/}
      {itemCount === 0 && (
        <Block
          borderBottom={`1px solid ${colors.gray.lighter}`}
          borderLeft={`1px solid ${colors.gray.lighter}`}
          borderRight={`1px solid ${colors.gray.lighter}`}
          boxSizing="border-box"
          position="absolute"
          width={width}
        >
          <Block backgroundColor="white" padding="10px">
            {isSearching ? t('textLoading') : t('textNoResults')}
          </Block>
        </Block>
      )}
      {itemCount > 0 && !infiniteScrolling && (
        <Block>
          <List
            highlightedIndex={highlightedIndex}
            height={height}
            inputValue={inputValue}
            items={items}
            rowCount={items.length}
            rowHeight={cache.rowHeight}
            rowRenderer={renderItem}
            onScroll={({ clientHeight, scrollTop }) => {
              const index = round(scrollTop / clientHeight)
              setScrollIndex(index)
            }}
            scrollToIndex={scrollToIndex}
            selectedItems={selectedItems}
            style={listStyles}
            width={width}
          />
          {showSelectAll && (
            <Flex
              backgroundColor="white"
              flexDirection="row"
              justifyContent="space-between"
              padding="20px 10px 20px 10px"
              borderBottom={`1px solid ${colors.gray.lighter}`}
              borderLeft={`1px solid ${colors.gray.lighter}`}
              borderRight={`1px solid ${colors.gray.lighter}`}
              bordertop={`1px solid ${colors.gray.lighter}`}
              boxSizing="border-box"
              width={width}
              position="relative"
              borderBottomLeftRadius={4}
              borderBottomRightRadius={4}
              top={height}
              zIndex={10}
            >
              <Block
                color={canSelectAll ? colors.blue.normal : colors.gray.light}
                display="inline-block"
                onClick={
                  canSelectAll
                    ? () => {
                        addItems(items)
                      }
                    : undefined
                }
              >
                {canSelectAll
                  ? t('inputSelect.selectAll', {
                      totalCount,
                      type,
                    })
                  : t('inputSelect.showCount', {
                      totalCount,
                      type,
                    })}
              </Block>
              {showRemoveItems && (
                <Block
                  display="inline-block"
                  color={
                    filteredResult.length > 0
                      ? colors.blue.normal
                      : colors.gray.light
                  }
                  onClick={
                    filteredResult.length > 0
                      ? () => {
                          removeItems(items)
                        }
                      : undefined
                  }
                >
                  {t('inputSelect.removeSelected', {
                    selectedCount: filteredResult.length,
                    type,
                  })}
                </Block>
              )}
            </Flex>
          )}
        </Block>
      )}
      {itemCount > 0 && infiniteScrolling && (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={() => {
            loadMoreRows(inputValue)
          }}
          rowCount={totalCount}
          threshold={50}
        >
          {({ onRowsRendered, registerChild }) => (
            <Block>
              <List
                highlightedIndex={highlightedIndex}
                height={height}
                inputValue={inputValue}
                items={items}
                rowCount={rowCount}
                rowHeight={cache.rowHeight}
                rowRenderer={renderItem}
                selectedItems={selectedItems}
                style={listStyles}
                width={width}
                refreshing={refreshing}
                ref={registerChild}
                onRowsRendered={onRowsRendered}
              />
              {showSelectAll && (
                <Flex
                  backgroundColor="white"
                  flexDirection="row"
                  justifyContent="space-between"
                  padding="20px 10px 20px 10px"
                  borderBottom={`1px solid ${colors.gray.lighter}`}
                  borderLeft={`1px solid ${colors.gray.lighter}`}
                  borderRight={`1px solid ${colors.gray.lighter}`}
                  bordertop={`1px solid ${colors.gray.lighter}`}
                  boxSizing="border-box"
                  width={width}
                  position="relative"
                  borderBottomLeftRadius={4}
                  borderBottomRightRadius={4}
                  top={height}
                  zIndex={10}
                >
                  <Block
                    color={
                      canSelectAll ? colors.blue.normal : colors.gray.light
                    }
                    display="inline-block"
                    onClick={
                      canSelectAll
                        ? () => {
                            addItems(items)
                          }
                        : undefined
                    }
                  >
                    {canSelectAll
                      ? t('inputSelect.selectAll', {
                          totalCount,
                          type,
                        })
                      : t('inputSelect.showCount', {
                          totalCount,
                          type,
                        })}
                  </Block>
                  {showRemoveItems && (
                    <Block
                      display="inline-block"
                      color={
                        filteredResult.length > 0
                          ? colors.blue.normal
                          : colors.gray.light
                      }
                      onClick={
                        filteredResult.length > 0
                          ? () => {
                              removeItems(items)
                            }
                          : undefined
                      }
                    >
                      {t('inputSelect.removeSelected', {
                        selectedCount: filteredResult.length,
                        type,
                      })}
                    </Block>
                  )}
                </Flex>
              )}
            </Block>
          )}
        </InfiniteLoader>
      )}
    </Block>
  )
}

SelectDropdownMenu.propTypes = {
  addItems: PropTypes.func,
  getMenuProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  inputValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  items: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  removeItems: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  scrollIndex: PropTypes.number.isRequired,
  selectAllLimit: PropTypes.number,
  showSelectAll: PropTypes.bool,
  setScrollIndex: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  width: PropTypes.number.isRequired,
}

function componentDidMount() {
  cache.clearAll()
}

function getCheckedStatus({ item, items, multi, value: checkedValue }) {
  if (!multi || !checkedValue) return {}

  // NOTE: Minus one to account for the select all option
  const itemCount = items.length - 1
  const checkedCount = checkedValue.length

  const isSelectAll = itemCount === checkedCount

  const itemChecked = checkedValue.includes(item.value)

  const allOptsChecked = itemCount > 0 && itemCount === checkedCount

  const checked = itemChecked || allOptsChecked

  if (checked) return 'checked'

  const indeterminate =
    isSelectAll && checkedCount > 0 && checkedCount < itemCount

  if (indeterminate) return 'indeterminate'

  return 'unchecked'
}

function renderItem(props) {
  const { getItemProps, highlightedIndex, items, multi, value = [] } = props

  return ({ key, index, parent, style }) => {
    const item = items[index] || {}
    const isActive = highlightedIndex === index
    const checkedStatus = getCheckedStatus({ item, items, multi, value })

    return (
      <CellMeasurer key={key} cache={cache} parent={parent} rowIndex={index}>
        {({ registerChild, measure }) => (
          <DropdownItem
            ref={registerChild}
            key={key}
            getItemProps={getItemProps}
            index={index}
            item={item}
            isActive={isActive}
            label={item.label}
            measure={measure}
            checkedStatus={checkedStatus}
            style={style}
            value={item.value}
            reference={item.reference}
          />
        )}
      </CellMeasurer>
    )
  }
}
