import 'leaflet-contextmenu'
import 'leaflet-editable'
import { booleanWithin } from '@turf/turf'
import { WithPermissions } from '@lighthouse/react-components'
import {
  filter,
  find,
  get,
  includes,
  isEqual,
  isNumber,
  min,
  values,
} from 'lodash'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getModule } from '@lighthouse/sdk'
import { Map, TileLayer, withLeaflet, ZoomControl } from 'react-leaflet'
import Immutable from 'seamless-immutable'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import emitter from 'utils/emitter'

import * as logger from 'utils/logger'
import { withTranslation } from 'react-i18next'

const areasModule = getModule('areas')
const geoModule = getModule('geo')
const signalModule = getModule('signals')

const DEFAULT_CENTER = [0, 0]
const DEFAULT_EDIT_OPTIONS = { drawingCursor: 'none' }
const MAX_BOUNDS = [
  [90, -220],
  [-90, 220],
]
const MAX_BOUNDS_VISCOSITY = 0.5
const MIN_ZOOM = 3

const MAP_TILE_CONFIGURATION = {
  map: {
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    url:
      'https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=NrAcvKk6V8POff4GpZYy',
  },
  satellite: {
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    url:
      'https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=NrAcvKk6V8POff4GpZYy',
  },
}

export default compose(
  WithPermissions,
  withLeaflet,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withHandlers({ setMarkerFilter }),
  withHandlers({ onAddSignal, onMapMove })
)(Base)

// NOTE it's possible we can simply move this component up to the top level.
// Just need to think whether it's worth the abstraction
function Base(props) {
  const {
    center,
    children,
    doubleClickZoom,
    disableTiles = false,
    hasModulePermission,
    mapTile = 'map',
    onAddSignal,
    onMapMove,
    zoom = MIN_ZOOM,
  } = props

  const { attribution, url } = MAP_TILE_CONFIGURATION[mapTile]

  const coords = center
    ? [get(center, 'coordinates.1'), get(center, 'coordinates.0')]
    : DEFAULT_CENTER

  const contextmenuItems = [
    {
      text: 'Add Signal',
      callback: onAddSignal,
    },
  ]

  /* NOTE
   * It's very important to add `contextmenu` as an option to the base map.
   * Without it, the hooks for hiding the context menu on map clicks aren't
   * fired, because the other context menu options are attached to markers and
   * other layers
   */

  return (
    <Map
      maxBoundsViscosity={MAX_BOUNDS_VISCOSITY}
      center={coords}
      contextmenu
      contextmenuInheritItems={false}
      contextmenuItems={
        hasModulePermission('signal', 'create') && contextmenuItems
      }
      doubleClickZoom={doubleClickZoom}
      /** NOTE enable for testing only
      onClick={onClick}
      **/
      editable
      editOptions={DEFAULT_EDIT_OPTIONS}
      maxBounds={MAX_BOUNDS}
      maxZoom={22}
      minZoom={MIN_ZOOM}
      onMoveEnd={onMapMove}
      zoom={zoom}
      zoomControl={false}
    >
      {!disableTiles && (
        <TileLayer
          attribution={attribution}
          crossOrigin
          maxZoom={22}
          url={url}
        />
      )}
      {children}
      <ZoomControl position="bottomright" />
    </Map>
  )
}

Base.propTypes = {
  center: PropTypes.object,
  children: PropTypes.node,
  disableTiles: PropTypes.bool,
  hasModulePermission: PropTypes.func.isRequired,
  mapView: PropTypes.string,
  onAddSignal: PropTypes.func.isRequired,
  onMapMove: PropTypes.func.isRequired,
  zoom: PropTypes.number,
}

// function onClick(e) {
//   // NOTE the usage of wrap... leaflet is does this unexpected thing where if
//   // you zoom out far enough you get copies of the 'earth'. Click those copies
//   // returns latlng values offset by the copy, not the real latlng. To get that
//   // you have to use `wrap()`. To demonstrate, log out the values without wrap
//   // and zoom right out and click on different copies of the earth
//   const latlng = e.latlng.wrap()
//   console.log(`${latlng.lng}, ${latlng.lat}`)
// }

function onAddSignal(props) {
  const {
    areaCache,
    findIntersectingById,
    history,
    location,
    saveSignal,
    selectedBuildingId,
    selectedFloor,
    selectedLocationId,
    setMarkerFilter,
    t,
  } = props

  return e => {
    const { latlng } = e

    const payloadGeometry = {
      coordinates: [latlng.lng, latlng.lat],
      type: 'Point',
    }

    const searchString = get(location, 'search', '')
    const parsed = queryString.parse(searchString)
    const { resource, id: queryId, lastParentId } = parsed
    const id = lastParentId || queryId

    if (!id) {
      logger.error('New signal :: an area must be selected first')
      emitter.emit('notification:add', {
        message: t('alert.message.selectBeforeAddingSignal'),
        title: t('alert.title.addSignal'),
        theme: 'info',
      })
      return
    }

    const area = get(areaCache, `${id}.entity`, {})

    if (!area) {
      logger.error('New signal :: the parent area was not defined')
      emitter.emit('notification:add', {
        message: t('alert.message.unableToCreateSignal'), //'Unable to create signal. Please contact support.',
        title: t('alert.title.addSignal'),
        theme: 'alert',
      })
      return
    }

    const areaType = resource === 'area' && get(area, 'type')

    const isInLocation = areaType === 'building' || areaType === 'geofence'

    const isLocation = areaType === 'location'

    const isInBuilding =
      areaType === 'corridor' ||
      areaType === 'floor' ||
      areaType === 'room' ||
      areaType === 'wall' ||
      areaType === 'transition' ||
      areaType === 'way' ||
      areaType === 'closure'

    const isValidAreaType = isLocation || isInLocation || isInBuilding

    if (!isValidAreaType && !lastParentId) {
      logger.error(
        'New signal :: the parent area must be of type location, building or geofence'
      )
      emitter.emit('notification:add', {
        message: t('alert.message.selectBeforeAddingSignal'),
        title: t('alert.title.addSignal'),
        theme: 'info',
      })

      return
    }

    const areaGeometry = get(area, 'geometry')
    const signalWithinArea =
      areaGeometry &&
      payloadGeometry &&
      booleanWithin(payloadGeometry, areaGeometry)

    if (!signalWithinArea) {
      logger.error('New signal :: must be located inside the selected area')
      emitter.emit('notification:add', {
        message: t('alert.message.signalMustBeInSelectedArea'), //'Signal must be created in area selected',
        title: t('alert.title.addSignal'),
        theme: 'info',
      })
      return
    }
    const parentId =
      selectedLocationId ||
      get(areaCache, `${selectedBuildingId}.entity.childOf[0]`) || // building childOf
      get(area, 'childOf[0]', false) || // geofence childOf
      lastParentId

    if (!parentId) {
      logger.error('New signal :: parentId must be defined')
      emitter.emit('notification:add', {
        message: t('alert.message.unableToCreateSignal'), //'Unable to create signal. Please contact support.',
        title: t('alert.title.addSignal'),
        theme: 'alert',
      })
      return
    }

    const buildingGeometry =
      selectedBuildingId &&
      get(areaCache, `${selectedBuildingId}.entity.geometry`)

    const signalIsWithinBuilding =
      payloadGeometry &&
      buildingGeometry &&
      booleanWithin(payloadGeometry, buildingGeometry)

    const buildingFloors =
      selectedBuildingId &&
      get(areaCache, `${selectedBuildingId}.entity.floors`)

    const floorLevels =
      buildingFloors && buildingFloors.map(floor => floor.level)

    const selectedFloorIsValid =
      floorLevels &&
      isNumber(selectedFloor) &&
      includes(floorLevels, selectedFloor)

    const floorsRef =
      signalIsWithinBuilding && floorLevels
        ? (selectedFloorIsValid && selectedFloor) || min(floorLevels)
        : []

    const payload = {
      childOf: parentId,
      floorsRef,
      geometry: payloadGeometry,
      label: 'Unassigned Beacon',
      properties: {
        beacon: {
          autoAssign: true,
        },
      },
      type: 'beacon',
    }

    const params = {
      optimistic: true,
    }

    saveSignal(null, payload, params)
      .then(({ data = {} }) => {
        const { _id: id, geometry, label } = data

        if (!id || !geometry) {
          logger.error('New signal :: missing required data')
          emitter.emit('notification:add', {
            message: t('alert.message.requiredDataMissing'), //'Required data missing',
            title: t('alert.title.addSignal'),
            theme: 'alert',
          })
          return
        }

        // NOTE show signals in case its unset
        setMarkerFilter('signal')

        const [lng, lat] = geometry.coordinates

        const nextSearch = queryString.stringify({
          action: 'edit',
          id,
          lastParentId: lastParentId || queryId,
          lat,
          lng,
          resource: 'signal',
          title: label,
          showMarker: true,
        })

        history.push({ search: `?${nextSearch}` })

        return data
      })
      .catch(error => {
        logger.error('NewSignalError', {
          err: err.message,
          stack: err.stack,
          payload,
        })
        emitter.emit('notification:add', {
          message: error.message, // server error
          title: t('alert.title.addSignal'),
          theme: 'alert',
        })
      })
  }
}

function onMapMove(props) {
  return e => {
    const { lat, lng } = e.target.getCenter()
    const zoom = e.target.getZoom()
    const center = {
      type: 'Point',
      coordinates: [lng, lat],
    }
    const currentCenter = get(props, 'properties.center')
    const currentZoom = get(props, 'properties.zoom')
    const hasMoved =
      center &&
      (center.coordinates[0] !== get(currentCenter, 'coordinates.0') ||
        center.coordinates[1] !== get(currentCenter, 'coordinates.1'))
    const hasZoomed = zoom && zoom !== currentZoom

    if (!hasMoved && !hasZoomed) return null

    const {
      _northEast: northEast,
      _southWest: southWest,
    } = e.target.getBounds()
    const neBounds = values(northEast)
    const swBounds = values(southWest)
    const north = neBounds[0]
    const east = neBounds[1]
    const south = swBounds[0]
    const west = swBounds[1]
    const bounds = [west, south, east, north]
    const newProperties = {}

    if (hasZoomed) newProperties.zoom = zoom
    if (hasMoved) {
      newProperties.center = center
    }
    if (hasMoved || hasZoomed) newProperties.bounds = bounds

    return props.setProperties(newProperties)
  }
}

function mapStateToProps(state) {
  const markerFilters = get(state.geo, 'markers.filters', {})
  const selectors = areasModule.selectors(state)()
  const areaCache = selectors.cache()

  return {
    areaCache,
    findIntersectingById: selectors.findIntersectingById,
    markerFilters,
    selectedBuildingId: get(state, 'geo.building.id'),
    selectedFloor: get(state, 'geo.building.floor'),
    selectedLocationId: get(state, 'geo.location.id'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveSignal: (id, payload) => dispatch(signalModule.save({}, payload, id)),
    setMarkerFilters: filters => dispatch(geoModule.setMarkerFilters(filters)),
  }
}

function setMarkerFilter(props) {
  const { markerFilters, setMarkerFilters } = props
  const { types = {} } = markerFilters

  return markerType => {
    if (types[markerType]) return

    const nextFilters = Immutable.setIn(
      markerFilters,
      ['types', markerType],
      true
    )

    setMarkerFilters(nextFilters)
  }
}
