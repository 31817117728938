import colors from 'config/theme/colors'

export const geoStyles = {
  default: {
    fillColor: 'none',
    color: 'none',
    fill: true,
    lineCap: 'round',
    lineJoin: 'round',
    fillOpacity: 1,
    smoothFactor: 1,
    stroke: true,
    weight: 1,
  },
  geofence: {
    default: {
      color: colors.blue.normal,
      dashArray: '5px',
      fillColor: colors.blue.normal,
      fillOpacity: 0.1,
      stroke: true,
      weight: 2,
    },
  },
  location: {
    default: {
      color: colors.blue.dark,
      fillColor: colors.blue.normal,
      fillOpacity: 0.25,
      opacity: 0.5,
      weight: 2,
    },
  },
  building: {
    default: {
      color: '#DDC9B0',
      fillColor: '#FFF8EA',
    },
    hover: {
      fillColor: '#F6ECDF',
    },
    active: {
      fillColor: '#F6ECDF',
    },
  },
  buildingHasImage: {
    default: {
      color: 'rgba(255,255,255,0)',
      fillColor: 'rgba(255,255,255,0)',
      className: 'click-disabled',
    },
    hover: {
      fillColor: 'rgba(255,255,255,0)',
      className: 'click-disabled',
    },
    active: {
      fillColor: 'rgba(255,255,255,0)',
      fillColor: 'click-disabled',
    },
  },
  room: {
    default: {
      fillColor: '#FFF8EA',
      color: '#DDC9B0',
    },
    hover: {
      fillColor: '#F6ECDF',
    },
    active: {
      fillColor: '#FFFDF9',
    },
  },
  floor: {
    default: {
      fillColor: '#F0EADB',
      color: '#B8ADA2',
      weight: 2,
    },
  },
  transition: {
    default: {
      fillColor: '#EFFFED',
      color: '#C7E9C4',
    },
  },
  corridor: {
    default: {
      fillColor: '#FFFFFF',
      color: '#DDC9B0',
    },
  },
  road: {
    default: {
      color: '#DDDDDD',
      weight: 3,
    },
  },
  object: {
    default: {
      fillColor: '#FFFBF3',
      color: '#F0E7DA',
    },
  },
  wall: {
    default: {
      fillColor: '#DDC9B0',
      strokeStyle: '#DDC9B0',
    },
  },
  // outOfService: {
  //   fillColor: '#FFEAEA',
  //   strokeStyle: '#DDB0B0',
  // },
}
